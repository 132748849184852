import { Link } from "@remix-run/react";
import { DateTime } from "luxon";
import type { Post } from "../repositories/post.repository";

type PostPreviewProps = {
  post: Post;
};

export function PostPreview({ post }: PostPreviewProps) {
  const formattedPostReleaseDate = DateTime.fromFormat(
    post.release_date,
    "yyyy-MM-dd"
  ).toFormat("dd MMMM yyyy");

  return (
    <article className="card mb-4">
      <header className="card-header">
        <div className="card-meta">
          <Link to={`/posts/${post.slug}`}>
            <time className="timeago" dateTime={post.created_at}>
              {formattedPostReleaseDate}
            </time>
          </Link>{" "}
          {/* in <Link to={`/posts/${post.slug}`}>Journey</Link> */}
        </div>
        <Link to={`/posts/${post.slug}`}>
          <h4 className="card-title">{post.title}</h4>
        </Link>
      </header>
      {post.cover_photo_url ? (
        <Link to={`/posts/${post.slug}`}>
          <img
            className="card-img"
            src={`/cover-photos/${post.cover_photo_url}`}
            alt=""
          />
        </Link>
      ) : null}
      <div className="card-body">
        <p className="card-text">{post.body}</p>
      </div>
    </article>
  );
}
